import React, { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { setDoc, doc, getDoc, Timestamp } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/global.css";
import CountryCodeDropdown from "./CountryCodeDropdown";
import Modal from "./Modal";
import {
  checkEmailExists,
  addBetaForExplore,
  addBetaForSilent,
  fetchLocation,
} from "../Utils/api";

const OnboardingForm = () => {
  const [loading, setLoading] = useState(true);
  const [emailExists, setEmailExists] = useState(false);
  const [licenseKeyExists, setLicenseKeyExists] = useState(false);
  const [phone, setPhone] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [appsumoEmail, setAppsumoEmail] = useState("");
  const [plan, setPlan] = useState("1");
  const [formData, setFormData] = useState({
    countryCode: "",
    whatsapp: "",
    name: "",
    occupation: "",
    otherOccupation: "",
    onboardingType: "",
    phone: "",
    email: "",
    onboardingPreference: "WA",
  });

  const [errors, setErrors] = useState({
    whatsapp: "",
    countryCode: "",
    email: "",
    appsumo: ""
  });

  const [isAssistedOnboardingPopupOpen, setIsAssistedOnboardingPopupOpen] =
    useState(false);
  const [isSelfOnboardingPopupOpen, setIsSelfOnboardingPopupOpen] =
    useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const licenseKey = queryParams.get("license_key");
  const source = queryParams.get("source");
  const navigate = useNavigate();

  useEffect(() => {
    let timer;

    const checkEmail = async () => {
      setLoading(true);
      const data = await checkEmailExists(email);
      setEmailExists(data.exists);
      // setPlan(data.plan);
      setLoading(false);
    };

    if (email) {
      timer = setTimeout(() => {
        checkEmail();
      }, 5000);
    } else if (licenseKey) {
      setLicenseKeyExists(true);
      setLoading(false);
    } else {
      setLicenseKeyExists(false);
      setEmailExists(false);
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, [email]);

  const validateWhatsAppNumber = (number) => {
    const numberPattern = /^\d+$/;
    return numberPattern.test(number);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "whatsapp") {
      if (!validateWhatsAppNumber(value)) {
        setErrors((prev) => ({
          ...prev,
          whatsapp: "Please enter a valid phone number.",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          whatsapp: "",
        }));
      }
    }

    if (name === "name") {
      if (value.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          name: "Name cannot be empty.",
        }));
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        setErrors((prev) => ({
          ...prev,
          name: "Name should contain only letters and spaces.",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          name: "",
        }));
      }
    }

    if (name === "occupation" && value !== "Others") {
      setErrors((prev) => ({
        ...prev,
        otherOccupation: "",
      }));
    }

    if (name === "otherOccupation") {
      if (formData.occupation === "Others" && value.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          otherOccupation: "Please specify your occupation.",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          otherOccupation: "",
        }));
      }
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!validateWhatsAppNumber(formData.whatsapp)) {
      setErrors((prev) => ({
        ...prev,
        whatsapp: "Please enter a valid phone number.",
      }));
      isValid = false;
    }

    if (formData.countryCode === "") {
      setErrors((prev) => ({
        ...prev,
        countryCode: "Please select a valid country code.",
      }));
      isValid = false;
    }

    if (formData.name.trim() === "") {
      setErrors((prev) => ({
        ...prev,
        name: "Name cannot be empty.",
      }));
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
      setErrors((prev) => ({
        ...prev,
        name: "Name should contain only letters and spaces.",
      }));
      isValid = false;
    }

    if (formData.occupation === "") {
      setErrors((prev) => ({
        ...prev,
        occupation: "Please select an occupation.",
      }));
      isValid = false;
    }

    if (
      formData.occupation === "Others" &&
      formData.otherOccupation.trim() === ""
    ) {
      setErrors((prev) => ({
        ...prev,
        otherOccupation: "Please specify your occupation.",
      }));
      isValid = false;
    }

    if (licenseKeyExists && formData.email.trim() === "") {
      setErrors((prev) => ({
        ...prev,
        email: "Email cannot be empty.",
      }));
      isValid = false;
    } else if (licenseKeyExists && !/\S+@\S+\.\S+/.test(formData.email)) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address.",
      }));
      isValid = false;
    }

    return isValid;
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "occupation" && value !== "Others") {
      setFormData((prev) => ({ ...prev, otherOccupation: "" }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleClick = async (option) => {
    setLoading(true);

    const data = await fetchLocation();
    const phoneNumber =
      formData.countryCode.replace("+", "") + formData.whatsapp;

    setPhone(phoneNumber);

    const finalData = {
      countryCode: formData.countryCode,
      whatsapp: formData.whatsapp,
      name: formData.name,
      onboardingType: option,
      phone: phoneNumber,
      email: email || formData.email,
      createdAt: Timestamp.now(),
      locationInfo: {
        ip: data.ip,
        city: data.city,
        region: data.region,
        country: data.country,
        postal: data.postal,
        latitude: data.latitude,
        longitude: data.longitude,
        timezone: data.timezone,
      },
      usagePreference: formData.onboardingPreference,
      occupation:
        formData.occupation === "Others"
          ? formData.otherOccupation
          : formData.occupation,
    };

    // if (licenseKey || source) {
    //   if (licenseKey) {
    //     finalData.appsumo_licenseKey = licenseKey;
    //     const appsumoUserRef = doc(db, "appsumo-users", licenseKey);
    //     await setDoc(
    //       appsumoUserRef,
    //       {
    //         phone: phoneNumber,
    //       },
    //       { merge: true }
    //     );
    //   }
    //   if (source) finalData.source = source;
    // }

    if (licenseKey || source) {
      if (licenseKey) {
        finalData.appsumo_licenseKey = licenseKey;

        const appsumoUserRef = doc(db, "appsumo-users", licenseKey);

        const docSnapshot = await getDoc(appsumoUserRef);
        console.log(docSnapshot)

        if (docSnapshot.exists()) {
          const existingData = docSnapshot.data();
          const tier = String(existingData.appsumo_tier); // Ensuring it's a string
          setPlan(tier);
          if (existingData.phone) {
            console.error("licence number already exists:", phoneNumber);
            setModalMessage("User is already associated with this AppSumo license key..");
            setIsModalOpen(true);
            setErrors((prev) => ({
              ...prev,
              appsumo: "This licence number is already registered.",
            }));
            setLoading(false);
            return
          } else {
            await setDoc(
                appsumoUserRef,
                {
                  phone: phoneNumber,
                },
                { merge: true }
            );
          }
        }
        else {
          console.error("Invalid AppSumo licence key.");
          setModalMessage("Invalid AppSumo licence key...");
          setIsModalOpen(true);
          setErrors((prev) => ({
            ...prev,
            appsumo: "Invalid AppSumo licence key.",
          }));
          setLoading(false);
          return
        }
      }

      if (source) finalData.source = source;
    }


    try {
      const docRef = doc(db, "onboarding-users", phoneNumber);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.error("Phone number already exists:", phoneNumber);
        setModalMessage("This phone number is already registered.");
        setIsModalOpen(true);
        setErrors((prev) => ({
          ...prev,
          phone: "This phone number is already registered.",
        }));
        return;
      } else {
        await setDoc(docRef, finalData);
        console.log("Onboarding data successfully saved for:", phoneNumber);
        if (option === "self-onboarding") {
          if (licenseKey){
            let appsumoUserRef = doc(db, "appsumo-users", licenseKey);

            const docSnapshot = await getDoc(appsumoUserRef);
            const existingData = await docSnapshot.data();
            console.log(existingData.appsumo_tier)
            await addBetaForExplore(
                email || formData.email,
                phoneNumber,
                data.timezone,
                formData.name,
                formData.onboardingPreference,
                existingData.appsumo_tier || plan
            );
          }
          else {
            await addBetaForExplore(
                email || formData.email,
                phoneNumber,
                data.timezone,
                formData.name,
                formData.onboardingPreference,
                plan
            );
          }

          // navigate("/success", {
          //   state: { onboardingPreference: formData.onboardingPreference },
          // });
          localStorage.setItem("phone", phoneNumber);
          localStorage.setItem("source", finalData.usagePreference);

          navigate("/gmail-auth");
        } else {
          await addBetaForSilent(
            email || formData.email,
            phoneNumber,
            data.timezone,
            formData.name,
            formData.onboardingPreference,
            plan
          );
          window.location.href =
            "https://calendly.com/prads-fasttrackr/onboarding";
        }
      }
    } catch (error) {
      console.error("handleClick error" + error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleSelfOnboardingClick = async () => {
    const isValid = await validateForm();
    if (isValid) {
      try {
        await handleClick("self-onboarding");
      } catch (error) {
        console.error("Error while onboarding - Self Onboarding: " + error);
        setModalMessage("An error occurred. Please try again.");
        setIsModalOpen(true);
      }
    }
  };

  // const handleRecommendedClick = async () => {
  //   const isValid = await validateForm();
  //   try {
  //     if (isValid) {
  //       setIsAssistedOnboardingPopupOpen(true);
  //     }
  //   } catch (error) {
  //     console.error("Error while onboarding -Recommended" + error);
  //     setModalMessage("An error occurred. Please try again.");
  //     setIsModalOpen(true);
  //   }
  // };

  const handleAssistedOnboardingConfirm = async () => {
    try {
      setIsAssistedOnboardingPopupOpen(false);
      await handleClick("recommended");
    } catch (error) {
      console.error("Error while onboarding -Recommended" + error);
      setModalMessage("An error occurred. Please try again.");
      setIsModalOpen(true);
    }
  };

  const handleSelfOnboardingConfirm = async () => {
    try {
      setIsSelfOnboardingPopupOpen(false);
      await handleClick("self-onboarding");
    } catch (error) {
      console.error("Error while onboarding -Recommended" + error);
      setModalMessage("An error occurred. Please try again.");
      setIsModalOpen(true);
    }
  };

  return (
    <div className="onboarding-container">
      <div className="logo-container">
        <img src="/images/ftlogo.svg" alt="FastTrackr Logo" className="logo" />
      </div>
    
        <form className="onboarding-form">
          <div className="logo-card-container">
            {loading ? (
              <div
                className="container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f9fafc",
                  flexDirection: "column",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                <img
                  src="/images/ftlogo.svg"
                  alt="FastTrackr Logo"
                  style={{
                    width: "250px",
                    height: "150px",
                    animation: "blink 1s infinite",
                  }}
                />
                <h2
                  style={{
                    marginTop: "20px",
                    color: "#2c3e50",
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  Just a moment...
                </h2>
                <p
                  style={{
                    color: "#7f8c8d",
                    fontSize: "16px",
                    marginTop: "10px",
                    letterSpacing: "0.5px",
                  }}
                >
                  We're loading your content.
                </p>
              </div>
            ) : emailExists || licenseKeyExists ? (
              <div className="container">
                <h2 className="header">We need some details to get you onboarded!</h2>

                <form>
                  <div className="input-group">
                    <label className="label">
                      WhatsApp{plan > 1 && "/iMessage"} Number:
                      <span className="info-button" aria-label="WhatsApp Number Info">
                        i
                        <span className="info-tooltip">
                          Please enter your WhatsApp{plan > 1 && "/iMessage"} number and country code.
                        </span>
                      </span>
                    </label>
                    <div className="whatsapp-input-group">
                      <CountryCodeDropdown
                        selectedCode={formData.countryCode}
                        setSelectedCode={(value) =>
                          setFormData({ ...formData, countryCode: value })
                        }
                        setErrors={setErrors}
                      />

                      <input
                        type="text"
                        name="whatsapp"
                        value={formData.whatsapp}
                        onChange={handleChange}
                        className="whatsapp-input"
                        required
                        maxLength={20}
                        placeholder={`Enter WhatsApp${plan > 1 ? "/iMessage " : ""}number`}
                      />
                    </div>
                    {errors.whatsapp && (
                      <p style={{ color: "red" }}>{errors.whatsapp}</p>
                    )}
                    {errors.countryCode && (
                      <p style={{ color: "red" }}>{errors.countryCode}</p>
                    )}
                  </div>

                  <div className="input-group">
                    <label className="label">
                      Full Name:
                      <span className="info-button" aria-label="Name Info">
                        i<span className="info-tooltip">Enter your Full name.</span>
                      </span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="input"
                      required
                      placeholder="Enter Full name"
                    />
                    {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                  </div>
                  {licenseKeyExists && (
                    <div className="input-group">
                      <label className="label">
                        Email:
                        <span className="info-button" aria-label="Email Info">
                          i<span className="info-tooltip">Enter your email address.</span>
                        </span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="input"
                        required
                        placeholder="Enter email"
                      />
                      {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                    </div>
                  )}

                  <div className="form-row">
                    <div className="input-group">
                      <label className="label">
                        What describes you best?
                        <span className="info-button" aria-label="Occupation Info">
                          i
                          <span className="info-tooltip">
                            Choose your current interest.
                          </span>
                        </span>
                      </label>
                      <div className="radio-group">
                        {[
                          "VC/PE",
                          "Founder/Entrepreneur",
                          "Leadership team/CXO",
                          "VP/SVP/Manager",
                          "Tech/AI Enthusiast",
                          "Others",
                        ].map((option) => (
                          <label className="radio-label" key={option}>
                            <input
                              type="radio"
                              name="occupation"
                              value={option}
                              checked={formData.occupation === option}
                              onChange={handleRadioChange}
                              required
                              className="radio-input"
                            />
                            {option}
                          </label>
                        ))}
                        {formData.occupation === "Others" && (
                          <input
                            type="text"
                            name="otherOccupation"
                            value={formData.otherOccupation}
                            onChange={handleChange}
                            className="input"
                            placeholder="Describe your role in own way."
                          />
                        )}
                      </div>
                      {errors.occupation && (
                        <p style={{ color: "red" }}>{errors.occupation}</p>
                      )}
                      {formData.occupation === "Others" && errors.otherOccupation && (
                        <p style={{ color: "red" }}>{errors.otherOccupation}</p>
                      )}
                    </div>

                    {/*{plan === 2 || plan === 3 ? (*/}
                    {/*  <div className="input-group">*/}
                    {/*    <label className="label">*/}
                    {/*      Usage Preference:*/}
                    {/*      <span className="info-button" aria-label="Onboarding Preference Info">*/}
                    {/*        i*/}
                    {/*        <span className="info-tooltip">*/}
                    {/*          Choose your preferred method for onboarding.*/}
                    {/*        </span>*/}
                    {/*      </span>*/}
                    {/*    </label>*/}
                    {/*    <div className="radio-group">*/}
                    {/*      <label className="radio-label">*/}
                    {/*        <input*/}
                    {/*          type="radio"*/}
                    {/*          name="onboardingPreference"*/}
                    {/*          value="WA"*/}
                    {/*          checked={formData.onboardingPreference === "WA"}*/}
                    {/*          onChange={handleRadioChange}*/}
                    {/*          className="radio-input"*/}
                    {/*        />*/}
                    {/*        WhatsApp*/}
                    {/*      </label>*/}
                    {/*      <label className="radio-label">*/}
                    {/*        <input*/}
                    {/*          type="radio"*/}
                    {/*          name="onboardingPreference"*/}
                    {/*          value="IM"*/}
                    {/*          checked={formData.onboardingPreference === "IM"}*/}
                    {/*          onChange={handleRadioChange}*/}
                    {/*          className="radio-input"*/}
                    {/*        />*/}
                    {/*        iMessage*/}
                    {/*      </label>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*) : (*/}
                    {/*  <input type="hidden" name="onboardingPreference" value="WA" />*/}
                    {/*)}*/}
                  </div>

                  {/*<div className="input-group">*/}
                    {/*<label className="label">*/}
                    {/*  Choose one of the onboarding options:*/}
                    {/*  <span*/}
                    {/*    className="info-button"*/}
                    {/*    aria-label="Onboarding options Info"*/}
                    {/*  >*/}
                    {/*    i*/}
                    {/*    <span className="info-tooltip">*/}
                    {/*      Choose one of the onboarding options.*/}
                    {/*    </span>*/}
                    {/*  </span>*/}
                    {/*</label>*/}
                  {/*</div>*/}
                  <div className="onboarding-options">
                    {/*<div*/}
                    {/*  className="card recommended"*/}
                    {/*  onClick={handleRecommendedClick}*/}
                    {/*  style={{ cursor: "pointer" }}*/}
                    {/*>*/}
                    {/*  <span className="label">Recommended</span>*/}
                    {/*  <h2>Assisted Onboarding</h2>*/}
                    {/*  <p className="text-white">*/}
                    {/*    FastTrackr expert will show you around*/}
                    {/*  </p>*/}
                    {/*</div>*/}

                    <div
                      className="card explore"
                      onClick={handleSelfOnboardingClick}
                      style={{ cursor: "pointer" }}
                    >
                      <h3>Next</h3>
                      {/*<p className="text-white">Take me to the next step !!</p>*/}
                    </div>
                  </div>
                  <div>
                    <Modal
                      isOpen={isModalOpen}
                      onClose={closeModal}
                      message={modalMessage}
                    />
                  </div>
                </form>

                {isAssistedOnboardingPopupOpen && (
                  <div className="popup-overlay">
                    <div className="popup-content">
                      <div className="head">
                        <h3 style={{ marginTop: "5px" }}>
                          You have selected assisted onboarding, are you sure?
                        </h3>
                        <h4>
                          Next screen will take you to the Calendly page to book a
                          15-minute onboarding call
                        </h4>
                      </div>
                      <div className="popup-buttons">
                        <button
                          className="button-accept"
                          onClick={handleAssistedOnboardingConfirm}
                        >
                          I understand
                        </button>
                        <button
                          className="button-cancel"
                          onClick={() => setIsAssistedOnboardingPopupOpen(false)}
                        >
                          Go back
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {isSelfOnboardingPopupOpen && (
                  <div className="popup-overlay">
                    <div className="popup-content">
                      <div className="head">
                        <h3 style={{ marginTop: "5px" }}>
                          You have selected self-onboarding, are you sure?
                        </h3>
                        <h4>
                          Next screen will take you to your FastTrackr AI assistant on{" "}
                          {formData.onboardingPreference === "IM" ? "iMessage" : "WhatsApp"}
                        </h4>
                      </div>
                      <div className="popup-buttons">
                        <button
                          className="button-accept"
                          onClick={handleSelfOnboardingConfirm}
                        >
                          I understand
                        </button>
                        <button
                          className="button-cancel"
                          onClick={() => setIsSelfOnboardingPopupOpen(false)}
                        >
                          Go back
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="container">
                <div className="permission-denied-card">
                  <div className="icon-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 24 24"
                    >
                      <circle cx="12" cy="12" r="10" fill="#ff4d4f" />
                      <path
                        d="M8 8l8 8M8 16l8-8"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <h2>You don't have permission to access this page</h2>
                  <p>Please reach out to FastTrackr AI team for assistance.</p>
                </div>
              </div>
            )}
          </div>
        </form>
      {/*</div>*/}
    </div>
  );
};

export default OnboardingForm;
